<template>
    <div class="container">
        <div 
            @mouseover="notificationHover(notif)"
            v-for="notif in notifications" :key="notif.id" 
            class="notification d-flex align-items-center justify-content-between"
        >
            <div>
                <h5 class="text-dark fw-bold"> 
                    <div v-if="!notif.checked" class="notification-dot mt-n2 ml-n1" />
                    <b-icon icon="bell" class="mr-2" /> 
                    {{ notif.type == "Notification" ? notif.description : t(notif.type) }}
                </h5>
                <p class="mb-0">
                    {{ t('theme') }}: 
                    <b>{{ notif.news_title }}</b>
                </p>
            </div>
            <div class="delete" @click="deleteNotification(notif.id)">
                <b-icon-x-circle />
            </div>
        </div>
        <div class="d-flex justify-content-center w-100" v-if="!notificationsLoaded">
            <b-spinner class="m-4" variant="success"></b-spinner>
        </div>
        <div v-if="notificationsLoaded && !notifications.length">
            <p class="text-center">{{ t('uHaveNoNotifications') }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotificationsPage',
    data() {
        return {
            notifications: [],
            notificationsRemoved: false,
            notificationsLoaded: false,
        }
    },
    methods: {
        notificationHover(notifInfo) {
            notifInfo.checked = true;
            this.$forceUpdate()
            this.checkNotificationsLeft()
        },
        async deleteNotification(id) {
            await this.axios.get('notification/' + id)
            for(let i = 0; i < this.notifications.length; i++) {
                let notifInfo = this.notifications[i]
                if(notifInfo.id == id) {
                    this.notifications.splice(i, 1)
                    return ;
                }
            }
        },
        checkNotificationsLeft() {
            for(let notifInfo of this.notifications) {
                if(!notifInfo.checked)
                    return ;
            }
            if(!this.notificationsRemoved) {
                this.$store.commit('removeNotificationsCounter')
                this.notificationsRemoved = true;
            }
        }
    },
    async mounted() {
        try {
            this.notifications = await this.$store.dispatch('getUserNotifications')
            this.notificationsLoaded = true;
        } catch(ignored) {
            this.$router.push('/')
        }
    },
}
</script>

<style scoped>
    .container {
        padding: 30px 0;
        max-width: 790px;
    }
    .notification {
        border-radius: 10px;
        width: 100%;
        padding: 20px;
        background-color: white;
        margin-bottom: 10px;
    }
    .notification-dot {
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 10px;
        background-color: var(--itpark);
    }
    .delete {
        cursor: pointer;
        padding: 7px 10px;
        height: 36px;
        border-radius: 20px;
        color: red;
    }
    .delete:hover {
        background-color: rgba(255, 0, 0, 0.226);
    }
    @media (max-width: 767px) {
        .container {
            padding: 10px 0;
        }
        .notification {
            border-radius: 0;
        }
    }
</style>